define("discourse/plugins/discourse-gamification/discourse/components/minimal-gamification-leaderboard", ["exports", "@glimmer/component", "discourse/lib/ajax", "@glimmer/tracking"], function (_exports, _component, _ajax, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "notTop10", _descriptor, this);
      _initializerDefineProperty(this, "model", _descriptor2, this);
      const count = this.args?.params?.count || 10;
      const data = {
        user_limit: count
      };

      // used in the right sidebar blocks theme component
      const leaderboardId = this.args?.params?.id || null;
      const endpoint = leaderboardId ? `/leaderboard/${leaderboardId}` : "/leaderboard";
      (0, _ajax.ajax)(endpoint, {
        data
      }).then(model => {
        this.model = model;
      });
    }
    get currentUserRanking() {
      const user = this.model?.personal;
      if (user) {
        this.notTop10 = user.position > 10;
      }
      return user || null;
    }
    get ranking() {
      this.model?.users?.forEach(user => {
        if (user.id === this.model.personal?.user?.id) {
          user.isCurrentUser = "true";
        }
        if (this.model.users.indexOf(user) === 0) {
          user.topRanked = true;
        }
      });
      return this.model?.users;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "notTop10", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2);
});