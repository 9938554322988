define("discourse/plugins/discourse-gamification/discourse/components/gamification-score", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "span",
    classNames: "gamification-score"
  });
});