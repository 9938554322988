define("discourse/plugins/discourse-gamification/discourse/templates/modal/leaderboard-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModalBody @title="gamification.leaderboard.modal.title">
    {{d-icon "award"}}
    {{html-safe (i18n "gamification.leaderboard.modal.text")}}
  </DModalBody>
  */
  {
    "id": "CcIgwinC",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"gamification.leaderboard.modal.title\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"award\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[[28,[37,3],[\"gamification.leaderboard.modal.text\"],null]],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-modal-body\",\"d-icon\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/templates/modal/leaderboard-info.hbs",
    "isStrictMode": false
  });
});