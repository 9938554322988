define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-gamification", ["exports", "@ember/controller", "@ember/object", "discourse-common/utils/decorators", "@ember/object/computed", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard", "discourse/plugins/discourse-gamification/discourse/components/modal/recalculate-scores-form"], function (_exports, _controller, _object, _decorators, _computed, _I18n, _ajax, _ajaxError, _service, _gamificationLeaderboard, _recalculateScoresForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.leaderboards.@each.updated_at"), _dec2 = (0, _decorators.default)("selectedLeaderboardId"), _dec3 = (0, _decorators.default)("site.groups"), _dec4 = (0, _decorators.default)("selectedLeaderboard.name"), (_obj = {
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    loading: false,
    creatingNew: false,
    newLeaderboardName: "",
    nameValid: (0, _computed.and)("newLeaderboardName"),
    toDate: "",
    fromDate: "",
    visibleGroupIds: [],
    includedGroupIds: [],
    excludedGroupIds: [],
    sortedLeaderboards(leaderboards) {
      leaderboards.map(leaderboard => {
        if (Number.isInteger(leaderboard.default_period)) {
          leaderboard.default_period = _I18n.default.t(`gamification.leaderboard.period.${_gamificationLeaderboard.LEADERBOARD_PERIODS[leaderboard.default_period]}`);
        }
      });
      return leaderboards?.sortBy("updated_at").reverse() || [];
    },
    selectedLeaderboard(id) {
      if (!id) {
        return;
      }
      id = parseInt(id, 10);
      const leaderboard = this.model.leaderboards.findBy("id", id);
      this.leaderboardChanged(leaderboard);
      return leaderboard;
    },
    siteGroups(groups) {
      // prevents group "everyone" to be listed
      return groups.filter(g => g.id !== 0);
    },
    leaderboardChanged(leaderboard) {
      this.set("visibleGroupIds", this.filterGroupsById(leaderboard.visible_to_groups_ids));
      this.set("includedGroupIds", this.filterGroupsById(leaderboard.included_groups_ids));
      this.set("excludedGroupIds", this.filterGroupsById(leaderboard.excluded_groups_ids));
    },
    filterGroupsById(groupIds) {
      if (!groupIds.length) {
        return [];
      }
      const filteredGroups = this.model.groups.filter(group => groupIds.includes(group.id));
      return filteredGroups.mapBy("id");
    },
    saveEditDisabled(name) {
      return !name;
    },
    createNewLeaderboard() {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      const data = {
        name: this.newLeaderboardName,
        created_by_id: this.currentUser.id
      };
      return (0, _ajax.ajax)("/admin/plugins/gamification/leaderboard", {
        data,
        type: "POST"
      }).then(leaderboard => {
        const newLeaderboard = _object.default.create(leaderboard);
        this.set("model.leaderboards", [newLeaderboard].concat(this.model.leaderboards));
        this.resetNewLeaderboard();
        this.setProperties({
          loading: false,
          selectedLeaderboardId: leaderboard.id
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    resetNewLeaderboard() {
      this.setProperties({
        creatingNew: false,
        newLeaderboardName: "",
        newLeaderboardId: null,
        toDate: "",
        fromDate: "",
        visibleGroupIds: [],
        includedGroupIds: [],
        excludedGroupIds: []
      });
    },
    destroyLeaderboard(leaderboard) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("gamification.leaderboard.confirm_destroy"),
        didConfirm: () => {
          this.set("loading", true);
          return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
            type: "DELETE"
          }).then(() => {
            this.model.leaderboards.removeObject(leaderboard);
            this.set("loading", false);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    saveEdit() {
      this.set("loading", true);
      const data = {
        name: this.selectedLeaderboard.name,
        to_date: this.toDate || this.selectedLeaderboard.to_date,
        from_date: this.fromDate || this.selectedLeaderboard.from_date,
        visible_to_groups_ids: this.visibleGroupIds,
        included_groups_ids: this.includedGroupIds,
        excluded_groups_ids: this.excludedGroupIds,
        default_period: this.selectedLeaderboard.default_period
      };
      return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${this.selectedLeaderboard.id}`, {
        data,
        type: "PUT"
      }).then(() => {
        this.selectedLeaderboard.set("updated_at", new Date());
        if (this.visibleGroupIds) {
          this.selectedLeaderboard.set("visible_to_groups_ids", this.visibleGroupIds);
        }
        if (this.includedGroupIds) {
          this.selectedLeaderboard.set("included_groups_ids", this.includedGroupIds);
        }
        if (this.excludedGroupIds) {
          this.selectedLeaderboard.set("excluded_groups_ids", this.excludedGroupIds);
        }
        this.setProperties({
          loading: false,
          selectedLeaderboardId: null,
          toDate: "",
          fromDate: "",
          visibleGroupIds: [],
          includedGroupIds: [],
          excludedGroupIds: [],
          default_period: "0"
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    recalculateScores() {
      this.modal.show(_recalculateScoresForm.default, {
        model: this.model
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "sortedLeaderboards", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortedLeaderboards"), _obj), _applyDecoratedDescriptor(_obj, "selectedLeaderboard", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectedLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "siteGroups", [_dec3], Object.getOwnPropertyDescriptor(_obj, "siteGroups"), _obj), _applyDecoratedDescriptor(_obj, "saveEditDisabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "saveEditDisabled"), _obj), _applyDecoratedDescriptor(_obj, "createNewLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "createNewLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "resetNewLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetNewLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "destroyLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "saveEdit", [_object.action], Object.getOwnPropertyDescriptor(_obj, "saveEdit"), _obj), _applyDecoratedDescriptor(_obj, "recalculateScores", [_object.action], Object.getOwnPropertyDescriptor(_obj, "recalculateScores"), _obj)), _obj)));
});