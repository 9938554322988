define("discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "discourse/lib/show-modal", "discourse/mixins/load-more", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _component, _decorators, _object, _showModal, _loadMore, _ajax, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LEADERBOARD_PERIODS = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const LEADERBOARD_PERIODS = _exports.LEADERBOARD_PERIODS = ["all_time", "yearly", "quarterly", "monthly", "weekly", "daily"];
  function periodString(periodValue) {
    switch (periodValue) {
      case 0:
        return "all";
      case 1:
        return "yearly";
      case 2:
        return "quarterly";
      case 3:
        return "monthly";
      case 4:
        return "weekly";
      case 5:
        return "daily";
      default:
        return "all";
    }
  }
  var _default = _exports.default = _component.default.extend(_loadMore.default, (_dec = (0, _decorators.default)("model.reason"), _dec2 = (0, _decorators.default)("model.users"), _dec3 = (0, _decorators.default)("model.users"), _dec4 = (0, _decorators.default)("model.users.[]"), (_obj = {
    router: (0, _service.inject)(),
    tagName: "",
    eyelineSelector: ".user",
    page: 1,
    loading: false,
    canLoadMore: true,
    period: "all",
    init() {
      this._super(...arguments);
      const default_leaderboard_period = periodString(this.model.leaderboard.default_period);
      this.set("period", default_leaderboard_period);
    },
    isNotReady(reason) {
      return reason !== undefined;
    },
    currentUserRanking() {
      const user = this.model.personal;
      return user || null;
    },
    winners(users) {
      return users.slice(0, 3);
    },
    ranking(users) {
      users.forEach(user => {
        if (user.id === this.currentUser?.id) {
          user.isCurrentUser = "true";
        }
      });
      return users.slice(3);
    },
    showLeaderboardInfo() {
      (0, _showModal.default)("leaderboard-info");
    },
    loadMore() {
      if (this.loading || !this.canLoadMore) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)(`/leaderboard/${this.model.leaderboard.id}?page=${this.page}&period=${this.period}`).then(result => {
        if (result.users.length === 0) {
          this.set("canLoadMore", false);
        }
        this.set("page", this.page += 1);
        this.set("model.users", this.model.users.concat(result.users));
      }).finally(() => this.set("loading", false)).catch(_ajaxError.popupAjaxError);
    },
    changePeriod(period) {
      this.set("period", period);
      return (0, _ajax.ajax)(`/leaderboard/${this.model.leaderboard.id}?period=${this.period}`).then(result => {
        if (result.users.length === 0) {
          this.set("canLoadMore", false);
          this.set("model.reason", result.reason);
        }
        this.set("page", 1);
        this.set("model.users", result.users);
        this.set("model.personal", result.personal);
      }).finally(() => this.set("loading", false)).catch(_ajaxError.popupAjaxError);
    },
    refresh() {
      this.router.refresh();
    }
  }, (_applyDecoratedDescriptor(_obj, "isNotReady", [_dec], Object.getOwnPropertyDescriptor(_obj, "isNotReady"), _obj), _applyDecoratedDescriptor(_obj, "currentUserRanking", [_dec2], Object.getOwnPropertyDescriptor(_obj, "currentUserRanking"), _obj), _applyDecoratedDescriptor(_obj, "winners", [_dec3], Object.getOwnPropertyDescriptor(_obj, "winners"), _obj), _applyDecoratedDescriptor(_obj, "ranking", [_dec4], Object.getOwnPropertyDescriptor(_obj, "ranking"), _obj), _applyDecoratedDescriptor(_obj, "showLeaderboardInfo", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showLeaderboardInfo"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "changePeriod", [_object.action], Object.getOwnPropertyDescriptor(_obj, "changePeriod"), _obj), _applyDecoratedDescriptor(_obj, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_obj, "refresh"), _obj)), _obj)));
});